import React, { Component } from "react";
import { Button, Col, Row } from "react-bootstrap";
import FB from "./images/facebook_icon.png";
import YoutubeIcon from "./images/youtube_icon.png";
import Linkedin from "./images/linkedin_icon.png";
import axios from "axios";
// import BSI from "./images/BSi.png";
import {
  Envelope,
  GeoAlt,
  Telephone,
  TelephoneFill,
  Clock,
} from "react-bootstrap-icons";
import BrandLogo from "./images/Nipro_NTS_tagline_RGB.png";
import styled from "styled-components";
import ReactGa from "react-ga";
import { EmailModal } from "./email-modal";

const SocialIcon = styled.img`
  margin: 5px;
`;
class ContactForm extends Component {
  state = {
    sending: false,
    userName: undefined,
    userEmail: undefined,
    userMessage: undefined,
    showEmailForm: false,
  };
  
  handleShowEmailForm(event) {
    this.setState({ showEmailForm: true });
  }

  hideEmailModal() {
    this.setState({ showEmailForm: false });
  }
  updateUserName(event) {
    this.setState({
      userName: event.target.value,
    });
  }

  updateUserEmail(event) {
    this.setState({
      userEmail: event.target.value,
    });
  }

  updateUserMessage(event) {
    this.setState({
      userMessage: event.target.value,
    });
  }

  render() {
    return (
      <div>
        <Row id="contactRow">
          <Col
            xs={12}
            sm={12}
            md={8}
            id="contactRowLg"
            ref={(section) => {
              this.Contact = section;
            }}
          >
            
            <br />
            <iframe width="100%" height="90%" paddingWidth="0" paddingheight="0"
            src="https://forms.office.com/Pages/ResponsePage.aspx?id=yZ61zV7aiEKPav757gS-nSlEXTjpGhhEviQPsXQp-EBUOUQzRFRZTUFPMVoyME1XWktOSk9LOTJZMy4u&embed=true" 
            frameborder="0" marginwidth="0" marginheight="0"  allowfullscreen webkitallowfullscreen mozallowfullscreen msallowfullscreen> </iframe>

          </Col>
          <Col xs={12} md={4} id="contactRowSm">
            <br />

            <img
              className="img-responsive contactBrand"
              src={BrandLogo}
              alt="H&S Logo"
            />
            <br />
            <br />

            <br />

            <p id="contact-info">
              <a
                href="https://www.google.com/maps/place/H%26S+Technical+Services,+Inc./@33.4722241,-111.7410175,17z/data=!3m1!4b1!4m5!3m4!1s0x872b08171ca8036b:0xfe720177ea455e4f!8m2!3d33.4722241!4d-111.7388288"
                target="_blank"
                rel="noopener noreferrer"
              >
                <GeoAlt size={15} /> 3110 N. Oakland, Ste. 101, Mesa, AZ 85215{" "}
              </a>
              <br />
              <a href="tel:+18009232486">
                {" "}
                <Telephone size={20} /> (800)-923-2486
              </a>
              <br />
              <a href="tel:+14805174918">
                {" "}
                <TelephoneFill size={20} /> (480)-517-4918
              </a>{" "}
              <br />
              <br />
              <a href="mailto:info@niprotechnical.com">
                {" "}
                <Envelope size={20} />
                {"  "} sales@niprotechnical.com
              </a>
              <br />
              <p id="blueColor">
                {" "}
                <Clock size={20} /> Monday - Friday
                <br />
                6:00am - 5:00pm MST
              </p>
              <br />
              <br />
              <ReactGa.OutboundLink
                eventLabel="Facebook icon event click"
                to="https://www.facebook.com/niprotechnical"
                target="_blank"
                trackerNames={["social-icons-fb"]}
              >
                <SocialIcon src={FB} alt="Facebook Icon" />
              </ReactGa.OutboundLink>
              <ReactGa.OutboundLink
                eventLabel="YouTube Icon event click"
                to="https://www.youtube.com/@niprotechnical2024"
                target="_blank"
                trackerNames={["social-icons-youtube"]}
              >
                <SocialIcon src={YoutubeIcon} alt="YouTube Icon" />
              </ReactGa.OutboundLink>
              <ReactGa.OutboundLink
                eventLabel="LinkedIn Icon event click"
                to="https://www.linkedin.com/company/h-s-technical-services-inc/?link=linkedin"
                target="_blank"
                trackerNames={["social-icons-linkedin"]}
              >
                <SocialIcon src={Linkedin} alt="Linkedin Icon" />
              </ReactGa.OutboundLink>
              <br />
              <br />
           {/*   <img src={BSI} alt="BSI Icon" style={{ maxWidth: "150px" }} /> */}
           <Button
            
            className="linkButton"
            onClick={() => this.handleShowEmailForm()}
          >
            Join our email list
          </Button>
            </p>
          </Col>
        </Row>

        {this.state.showEmailForm && (
          <EmailModal
            showModal={this.state.showEmailForm}
            close={this.hideEmailModal.bind(this)}
          />
        )}
      </div>
    );
  }
}

export default ContactForm;
